import { ParsedUrlQuery } from 'querystring';
import React, { useEffect } from 'react';
import { GetServerSideProps, GetServerSidePropsResult } from 'next';
import { Layout } from '$layouts/layout';
import type { ICMSPage } from '$models';
import { PageTypes } from '$constants/pages';
import { ServerSidePropsWithFrameFunc, SSPResult } from '$lib/serversideprops-with-frame';
import { Spots } from '$components/spots/spots';
import { Container } from '$components/layouts';
import { FindStorePage } from '$templates/find-store-page';
import { StoreDetailPage } from '$templates/store-detail-page';
import { ThemaPage } from '$templates/thema-page/thema-page';
import { getThemaPageSSRProps } from '$templates/thema-page/thema-page-ssr.helper';
import { getPersonPageSSRProps } from '$templates/person-page/person-page-ssr.helper';
import { getSeriesPageSSRProps } from '$templates/series-page/series-page-ssr.helper';
import { SeriesPage } from '$templates/series-page/series-page';
import { OrderConfirmationPage } from '$templates/order-confirmation-page';
import { useAuthentication, useCMSRoute } from '~/store';
import { AuthorIndexPage } from '$templates/author-index-page';
import { CategoryPage, getCategoryPageSSRProps } from '$templates/category-page';
import { getSearchPageSSRProps, SearchPage } from '$templates/search-page';
import { PersonPage } from '$templates/person-page';
import { ImageRichTextpage } from '$templates/image-rich-text-page';
import { calculateRoughlyInViewOnClient, getPageTypeByData } from '$lib/helpers';

type Props = {
    page: ICMSPage;
    isCrawler: boolean;
    pageSpecificProps: never;
    query: ParsedUrlQuery;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PAGES: { [key: string]: React.ComponentType<any> } = {
    [PageTypes.AuthorIndexPage]: AuthorIndexPage,
    [PageTypes.CategoryPage]: CategoryPage,
    [PageTypes.FindStorePage]: FindStorePage,
    [PageTypes.SearchPage]: SearchPage,
    [PageTypes.StoreDetailPage]: StoreDetailPage,
    [PageTypes.OrderConfirmationPage]: OrderConfirmationPage,
    [PageTypes.PersonPage]: PersonPage,
    [PageTypes.RichTextPage]: ImageRichTextpage,
    [PageTypes.RichTextPageAlt]: ImageRichTextpage,
    [PageTypes.ImageRichTextPage]: ImageRichTextpage,
    [PageTypes.Error]: ImageRichTextpage,
    [PageTypes.ThemaPage]: ThemaPage,
    [PageTypes.SeriesPage]: SeriesPage,
};

const DynamicPage = (props: SSPResult<Props>): JSX.Element => {
    const { navigation, page, pageSpecificProps, resolvedUrl, routes, query, ...rest } = props;
    const pageType = getPageTypeByData(page);
    const { setRoutes } = useCMSRoute();
    const { setShowMemberAccessPanel } = useAuthentication();

    useEffect(() => {
        if (query.authentication === 'failed') {
            setShowMemberAccessPanel('login', query.returnUrl.toString());
        }
    }, [query]);

    useEffect(() => {
        setRoutes(routes);
    }, [routes, setRoutes]);

    const PAGE = pageType ? PAGES[pageType] : undefined;

    return (
        <Layout
            title={page.title ?? ''}
            navigation={navigation}
            metaData={page.metaHtmlHeadModel}
            breadcrumbs={page.breadCrumb}
        >
            {!!PAGE && <PAGE navigation={navigation} {...page} {...pageSpecificProps} {...rest} />}
            <Container>{!PAGE && <Spots elements={page.content} h1 />}</Container>
        </Layout>
    );
};

export const getServerSideProps: GetServerSideProps = ServerSidePropsWithFrameFunc<Partial<Props>>(
    async (context, page, _, isCrawler): Promise<GetServerSidePropsResult<Partial<Props>>> => {
        const { res, resolvedUrl, query } = context;

        const mapPageHeaders: string[] = [];

        if (isCrawler) {
            res.setHeader('Cache-Control', `private, max-age=600, must-revalidate`);
        }
        try {
            const { data: pageData, status, headers } = page;

            if (!pageData && status === 500) {
                throw new Error('Internal Server Error');
            }

            if (!pageData) {
                return {
                    notFound: true,
                };
            }

            calculateRoughlyInViewOnClient(pageData);

            // Map headers from page response.
            // Allows the CMS to handle how pages are cached etc.
            mapPageHeaders.forEach((headerKey) => {
                const header = headers.get(headerKey);
                header && res.setHeader(headerKey, header);
            });

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let pageSpecificProps: any = {};

            if (status < 300) {
                const pageType = getPageTypeByData(pageData);

                switch (pageType) {
                    case PageTypes.CategoryPage:
                    case PageTypes.CategoryPageAlt:
                        pageSpecificProps = await getCategoryPageSSRProps(pageData, query);
                        break;
                    case PageTypes.PersonPage:
                        pageSpecificProps = await getPersonPageSSRProps(query);
                        break;
                    case PageTypes.ThemaPage:
                        overridePageData(pageData, 'thema', PageTypes.ThemaPage);
                        pageSpecificProps = await getThemaPageSSRProps(query);
                        break;
                    case PageTypes.SeriesPage:
                        pageSpecificProps = await getSeriesPageSSRProps(query);
                        break;
                    case PageTypes.SearchPage:
                        pageSpecificProps = await getSearchPageSSRProps(query, context.req.cookies.hello_retail_id);
                        break;
                    default:
                        break;
                }

                if (
                    pageSpecificProps &&
                    // eslint-disable-next-line no-prototype-builtins
                    (pageSpecificProps.hasOwnProperty('redirect') || pageSpecificProps.hasOwnProperty('notFound'))
                ) {
                    return pageSpecificProps;
                }
            }
            return {
                props: {
                    page: pageData,
                    pageSpecificProps,
                    isCrawler,
                    query,
                },
            };
        } catch (error) {
            console.error(`Failed loading: ${resolvedUrl}`);
            console.error(error);
            res.statusCode = 500;
            throw error;
        }
    }
);

function overridePageData(page: ICMSPage, dataName: string, pageType: PageTypes) {
    const pages = page.content.filter((x) => x[dataName]);
    if (!pages?.length) {
        console.error(`Missing page data for: ${pageType}`);
    } else {
        pages.forEach((x) => (x.spotName = pageType));
    }
}

export default DynamicPage;
