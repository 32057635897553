import React, { FC, memo } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { ICMSPage, IFullSearchResult, IThemaPage } from '$models';
import { Container, Flex } from '$components/layouts';
import { Spots } from '$components/spots/spots';
import { usePageData, useTranslation } from '$hooks';
import { PageTypes } from '$constants';
import { SearchModule } from '~/modules/filter';
import { ThemaLink } from './thema-link';
import { ThemaChildren } from './thema-children';
import { Headline } from '$components/elements/headline';
import { Collapsible } from '$components/elements/collapsible';
import { RichText } from '$components/elements/rich-text';
import { Text } from '$components/elements/text';
import { SearchContentTypes } from '~/modules/search';

type Props = { initialData: IFullSearchResult[]; themaCodes: string[] } & ICMSPage;

export const ThemaPage: FC<Props> = memo((props: Props) => {
    const { content, bottomContent, initialData, themaCodes } = props;
    const { pageData, topSpots, bottomSpots } = usePageData<IThemaPage>(content, bottomContent, PageTypes.ThemaPage);
    const { translate } = useTranslation();
    const theme = useTheme();

    return (
        <Container>
            <Spots elements={topSpots} />
            {!!pageData && (
                <>
                    <Headline>{pageData.thema.codeDescription}</Headline>
                    <Collapsible
                        collapsedHeight={200}
                        collapseCTAText={translate('productPage.productDescription.showLessDetails')}
                        expandCTAText={translate('productPage.productDescription.showAllDetails')}
                    >
                        <ThemaContainer column gap="extrasmall">
                            {!!pageData.themaParent?.thema && (
                                <div>
                                    <ThemasTitle first as="h5" size={theme.fontSizes.md} font="heading">
                                        {translate('thema.details.parentLabel')}:
                                    </ThemasTitle>
                                    <ThemaLink thema={pageData.themaParent} />
                                </div>
                            )}
                            {!!pageData.themaChildren?.length && (
                                <div>
                                    <ThemasTitle as="h5" size={theme.fontSizes.md} font="heading">
                                        {translate('thema.details.childrenLabel')}:
                                    </ThemasTitle>

                                    <ThemaChildren themaChildren={pageData.themaChildren} />
                                </div>
                            )}
                        </ThemaContainer>
                    </Collapsible>
                </>
            )}
            <SearchModule
                initialData={initialData}
                mode={SearchContentTypes.Products}
                searchQuery=""
                pageId={themaCodes.join('/')}
            />
            <Spots elements={bottomSpots} />
            {!!pageData?.themaBody && (
                <ThemaPageContent column alignItems="center">
                    <RichText text={pageData?.themaBody} />
                </ThemaPageContent>
            )}
        </Container>
    );
});

const ThemaContainer = styled(Flex)(({ theme }) => ({
    padding: theme.space[5],
    backgroundColor: theme.colors.imgBg,
    borderRadius: '5px',
}));

const ThemasTitle = styled(Text)<{ first?: boolean }>(({ theme, first }) => ({
    lineHeight: 1,
    marginBottom: theme.space[1],
    marginTop: first ? '0' : theme.space[1],
}));

const ThemaPageContent = styled(Flex)({
    maxWidth: '50rem',
    margin: '0 auto',
});
