import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { IThemaContainer } from '$models';
import { Link } from '$components/elements/link';

type Props = {
    thema: IThemaContainer;
    className?: string;
};

export const ThemaLink: FC<Props> = ({ thema, className }: Props) => {
    const theme = useTheme();

    return (
        <StyledLink themedLink={theme.colors.red} className={className} href={thema.url}>
            {thema.thema.codeDescription}
        </StyledLink>
    );
};

const StyledLink = styled(Link)(({ theme }) => ({
    color: theme.colors.red,
    cursor: 'pointer',
}));
