import React, { FC, Fragment } from 'react';
import { IThemaContainer } from '$models';
import { ThemaLink } from './thema-link';
import { Grid } from '$components/layouts';
import { mq } from '$lib/helpers';

type Props = {
    themaChildren: IThemaContainer[];
};
export const ThemaChildren: FC<Props> = ({ themaChildren }: Props) => (
    <Grid
        flow="row"
        columns="1fr"
        breakpoints={{
            [mq('md')]: {
                columns: '1fr 1fr',
            },
        }}
    >
        {themaChildren.map((x) => (
            <Fragment key={x.url}>
                <ThemaLink thema={x} />
            </Fragment>
        ))}
    </Grid>
);
