import { FC } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { hoverUnderline } from '$lib/style-helpers';
import { Button } from '../button';
import { Link } from '../link';
import { SvgIcon } from '../svg-icon';

type Props = {
    onClick?: () => void;
    href?: string;
};

export const BackLink: FC<Props> = ({ onClick, href, children }) => {
    const theme = useTheme();

    return href ? (
        <StyledLink onClick={onClick} href={href}>
            <LinkIcon svg="arrowLeft" color={theme.colors.red} size="lg" />
            {children}
        </StyledLink>
    ) : (
        <StyledButtonLink variant="custom" onClick={onClick}>
            <LinkIcon svg="arrowLeft" color={theme.colors.red} size="lg" />
            {children}
        </StyledButtonLink>
    );
};

const StyledButtonLink = styled(Button)(({ theme: { colors } }) => ({
    textDecoration: 'none',
    color: colors.red,
    alignItems: 'center',
    borderRadius: 'initial',
    ...hoverUnderline(),
}));

const StyledLink = styled(Link)(({ theme: { colors, space } }) => ({
    color: colors.red,
    display: 'inline-flex',
    alignItems: 'center',
    minHeight: space[8],
    ...hoverUnderline(),
}));

const LinkIcon = styled(SvgIcon)(({ theme: { space } }) => ({
    paddingRight: space[4],
}));
