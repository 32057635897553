import React from 'react';
import styled from '@emotion/styled';
import { PolymorphicComponentProps } from 'react-polymorphic-box';
import { switchProp } from 'styled-tools';

type StyledHeadlineProps = {
    /**
     * The styling size
     *
     * If you want to not apply any font-size or override using `css={}` set to `null`
     */
    size?: 1 | 2 | 3 | 4 | 5 | 6 | null;
    color?: string;
};

export const StyledHeadline = styled.h1<StyledHeadlineProps>(
    ({ theme, color }) => ({
        color: color || theme.colors.black,
        lineHeight: theme.lineHeights.headline,
        fontFamily: theme.fontFamilies.heading,
        fontWeight: theme.fontWeights.black,
    }),
    switchProp('size', {
        '1': ({ theme }) => ({
            ...theme.mixins.useTextStyle('display1'),
        }),
        '2': ({ theme }) => ({
            ...theme.mixins.useTextStyle('display2'),
        }),
        '3': ({ theme }) => ({
            ...theme.mixins.useTextStyle('display3'),
        }),
        '4': ({ theme }) => ({
            ...theme.mixins.useTextStyle('display4'),
        }),
        '5': ({ theme }) => ({
            ...theme.mixins.useTextStyle('display5'),
        }),
        '6': ({ theme }) => ({
            ...theme.mixins.useTextStyle('display6'),
        }),
    })
);

// Component-specific props

const DEFAULT_ELEMENT = 'h1';

/**
 * Merge of StyledHeadline's own props with those inherited from `E` - the underlying dynamic element type (`"h1"` by default)
 */
export type HeadlineProps<E extends React.ElementType> = PolymorphicComponentProps<E, StyledHeadlineProps>;

/**
 * Use `size` to choose headline style. Markup will follow suit (ie. `size={3}` renderes as `<h3>` by default)
 *
 * Override rendered markup with `as` attribute. For instance you can render a h3 like a size 1:
 *
 * ```tsx
 * <Headline as="h3" size={1}>An h3 in markup. Styled like an h1.</Headline>
 * ```
 */

export const Headline: <E extends React.ElementType = typeof DEFAULT_ELEMENT>(
    props: HeadlineProps<E>
) => React.ReactElement | null =
    // eslint-disable-next-line react/display-name
    React.forwardRef(
        <E extends React.ElementType = typeof DEFAULT_ELEMENT>(
            { size = 1, as, ...restProps }: HeadlineProps<E>,
            ref: typeof restProps.ref
        ) => {
            const mappedAs = (size && size >= 1 && size <= 6 ? `h${size}` : DEFAULT_ELEMENT) as
                | 'h1'
                | 'h2'
                | 'h3'
                | 'h4'
                | 'h5'
                | 'h6';
            return <StyledHeadline ref={ref} size={size} as={as || mappedAs} {...restProps} />;
        }
    );
